import * as React from "react";

import { VacancyBadgeWrapper } from "src/components/vacancy-badge";

import Text from "../text";
import { GravitywellTheme } from "../../config/theme";
import styled from "../../theme/styled";
import { colourHelper } from "../../theme/theme-helper";
import {
  ColumnWidthWrapper,
  FullWidthWrapper
} from "../confines-wrappers";
import CopyRightFooterWrapper from "../copyright-footer";
import Icon from "../icon";
import Link from "../link";

const FooterWrapper = styled.div`
  border-top: 0.5rem solid ${({ theme }) => theme.color.brandColor};
  background: ${({ theme }) => theme.color.warmGrey1};
`;

const FooterContent = styled.div`
  position: relative;

  .top .text,
  .links .address,
  .links .map,
  .links .contact .contact-details,
  .links .contact .contact-details a,
  .links .navigation {
    font-weight: 700;
    font-size: 1.8rem;
  }

  .links {
    display: block;
    overflow: hidden;

    .address-wrapper,
    .contact-wrapper,
    .navigation-wrapper {
      display: block;
      align-items: stretch;
      padding-top: 6.8rem;
      padding-bottom: 0;

      &:first-child {
        padding-top: 4.2rem;
      }
    }

    .address,
    .map,
    .contact .contact-details,
    .contact .contact-details a,
    .contact .socials .follow-text,
    .navigation {
      text-align: center;
    }

    .address,
    .map,
    .contact .contact-details,
    .contact .socials .follow-text {
      display: block;
    }

    .address-wrapper {
      .map {
        padding-top: 2rem;
        font-weight: 900;
      }
    }

    .contact-wrapper {
      .contact {
        .contact-details {
          .telephone,
          .email {
            color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
            border: 0;

            &:hover {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
            }
          }
        }

        .socials {
          padding-top: 2rem;
          text-align: center;

          .social-icons {
            display: inline-block;
            padding-left: 0;
            padding-bottom: 0;
            padding-top: 0.8rem;
            list-style: none;

            li {
              display: inline-block;
              margin-bottom: 0;
              padding: 0 1rem;

              a {
                .icon {
                  fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
                }

                &:hover {
                  .icon {
                    fill: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColorLight"
    })};
                  }
                }
              }
            }
          }
        }
      }
    }

    .navigation-wrapper {
      .navigation {
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 0;
        list-style: none;

        li {
          position: relative;
          margin-bottom: 0;

          a {
            position: relative;

            &:hover {
              color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
            }

            .counter {
              position: absolute;
              top: -0.2rem;
              right: -2rem;
              width: 1.5rem;
              height: 1.5rem;
              line-height: 1em;
              background-color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "brandColor"
    })};
              border-radius: 50%;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: ${({ theme }) =>
    colourHelper({
      theme,
      variant: "white"
    })};
                font-size: 1rem;
                line-height: 1em;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .links {
      flex-direction: row;

      .address,
      .map,
      .contact .contact-details,
      .contact .contact-details a,
      .contact .socials .follow-text,
      .navigation {
        text-align: left;
      }

      .address-wrapper,
      .contact-wrapper,
      .navigation-wrapper {
        padding-top: 4.2rem;
      }

      .address-wrapper,
      .contact-wrapper {
        float: left;
      }

      .address-wrapper {
        margin-right: 6%;
        width: 22%;
      }

      .contact-wrapper {
        width: 33%;
      }

      .navigation-wrapper {
        float: right;
        width: 24%;
      }

      .contact-wrapper {
        .contact {
          .socials {
            text-align: left;

            .social-icons {
              li {
                &:first-child {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .links {
      .address-wrapper,
      .contact-wrapper,
      .navigation-wrapper {
        padding-top: 0 !important;
      }

      .address-wrapper {
        margin-right: 5.6rem;
        width: 17rem;
      }

      .contact-wrapper {
        width: 22.6rem;
      }

      .navigation-wrapper {
        margin-right: 15%;
        width: 24%;
      }
    }
  }
`;

const FooterTop = styled.div`
  text-align: center;

  .top {
    display: inline-block;
    position: relative;
    padding-left: 5.2rem;
    cursor: pointer;

    &:hover {
      .icon {
        fill: ${({ theme }) => colourHelper({
    theme,
    variant: "brandColor"
  })};
      }
    }

    .text {
      transition: 0.15s ease-in-out;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: ${({ theme }) => colourHelper({
    theme,
    variant: "black"
  })};
    }

    .icon {
      transition: 0.15s ease-in-out;
    }
  }

  @media (min-width: 1024px) {
    .top {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
`;

const Line = styled.span`
  display: block;
  margin: 0 auto 3.4em;
  height: 0.5rem;
  width: 5rem;
  background: ${GravitywellTheme.color.fonts.secondary};

  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const handleTopClick = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

const Footer = () => (
  <FooterWrapper
    id="footer"
    role="contentinfo"
  >
    <FullWidthWrapper>
      <ColumnWidthWrapper className="content-container">
        <FooterContent>
          <FooterTop>
            <div
              className="top"
              onClick={() => handleTopClick()}
            >
              <span className="text">
                Top
              </span>

              <Icon
                className="icon"
                icon="rocketLaunch"
                size={6}
              />
            </div>
          </FooterTop>

          <div
            className="links"
            role="navigation"
            aria-label="Supplementary navigation (footer)"
          >
            <div className="address-wrapper">
              <Line />

              <Text
                className="address"
                fontSize="body_2"
                fontWeight="bold"
                lineHeight="large"
              >
                <>
                  Unit 22
                  <br />
                  Impact Working

                  <br />
                  Spectrum

                  <br />
                  Bond Street

                  <br />
                  Bristol

                  <br />
                  BS1 3LG
                </>
              </Text>

              <Text
                className="map"
                fontSize="body_2"
                fontWeight="heavy"
                lineHeight="large"
                fontColor="secondary"
              >
                <Link
                  target="_blank"
                  href="https://goo.gl/maps/qvvv9"
                  type="styled"
                >
                  Map
                </Link>
              </Text>
            </div>

            <div className="contact-wrapper">
              <Line />

              <div className="contact">
                <Text
                  className="contact-details"
                  fontSize="body_2"
                  fontWeight="bold"
                  lineHeight="large"
                >
                  <>
                    <Link
                      className="telephone"
                      href="tel:+441173790309"
                      type="none"
                    >
                      +44 (0) 117 379 0309
                    </Link>

                    <br />

                    <span className="off-screen">
                      Or email us:
                      {" "}
                    </span>

                    <Link
                      className="email"
                      href="mailto:hello@gravitywell.co.uk"
                      type="none"
                    >
                      hello@gravitywell.co.uk
                    </Link>
                  </>
                </Text>

                <div className="socials">
                  <Text
                    className="follow-text"
                    fontSize="small_3"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    Follow us
                  </Text>

                  <ul className="social-icons">
                    <li>
                      <Link
                        target="_blank"
                        href="https://www.linkedin.com/company/gravitywell/"
                        type="none"
                      >
                        <Icon
                          className="icon"
                          icon="linkedIn"
                          size={2.2}
                        />
                      </Link>
                    </li>

                    <li>
                      <Link
                        target="_blank"
                        href="https://twitter.com/gravitywelluk/"
                        type="none"
                      >
                        <Icon
                          className="icon"
                          icon="twitter"
                          size={2.2}
                        />
                      </Link>
                    </li>

                    <li>
                      <Link
                        target="_blank"
                        href="https://www.youtube.com/channel/UCNboY3WoOtu1Dhdqv-uzgcA?sub_confirmation=1"
                        type="none"
                      >
                        <Icon
                          className="icon"
                          icon="youtube"
                          size={2.2}
                        />
                      </Link>
                    </li>

                    <li>
                      <Link
                        target="_blank"
                        href="https://www.facebook.com/gravitywelluk/"
                        type="none"
                      >
                        <Icon
                          className="icon"
                          icon="facebook"
                          size={2.2}
                        />
                      </Link>
                    </li>

                    <li>
                      <Link
                        target="_blank"
                        href="https://www.instagram.com/gravitywelluk/"
                        type="none"
                      >
                        <Icon
                          className="icon"
                          icon="instagram"
                          size={2.2}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <nav
              role="navigation"
              className="navigation-wrapper"
            >
              <Line />

              <ul className="navigation">
                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/projects"
                      type="none"
                    >
                      Work
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/about-us"
                      type="none"
                    >
                      Team
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/careers"
                      type="none"
                    >
                      <VacancyBadgeWrapper>
                        Careers
                      </VacancyBadgeWrapper>
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/latest"
                      type="none"
                    >
                      Latest
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/mission-statement/"
                      type="none"
                    >
                      Mission Statement
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/incubator/"
                      type="none"
                    >
                      Incubator
                    </Link>
                  </Text>
                </li>

                <li>
                  <Text
                    fontSize="body_2"
                    fontWeight="bold"
                    lineHeight="large"
                  >
                    <Link
                      href="/privacy-policy"
                      type="none"
                    >
                      Privacy policy
                    </Link>
                  </Text>
                </li>
              </ul>
            </nav>
          </div>
        </FooterContent>
      </ColumnWidthWrapper>
    </FullWidthWrapper>

    <CopyRightFooterWrapper />
  </FooterWrapper>
);

export default Footer;
